<template>
  <app-form-select
    v-model="model"
    :items="items"
    label="Enrolment mode"
    :required="required"
  />
</template>

<script lang="ts" setup>
const properties = defineProps<{
  modelValue: DatabaseEnum<"course_session_attendee_enrolment_mode">;
  required?: boolean;
}>();

const model = useVModel(properties, "modelValue");

const items: DatabaseEnum<"course_session_attendee_enrolment_mode">[] = [
  "SINGLE_CUSTOMER",
  "CREDITS_CUSTOMER",
  "FREE",
  "FREE_PROSPECT",
];
</script>
