<template>
  <app-modal class="relative" :title="title" :width="600" @submit="onSubmit">
    <course-session-attendee-enrolment-mode-select
      v-model="form.enrolment_mode"
      required
    />
  </app-modal>
</template>

<script lang="ts" setup>
import type { AttendeeView } from "~/business-areas/course-session-attendee/course-session-attendee.model";

const properties = defineProps<{
  attendee: AttendeeView;
}>();

const title = computed(
  () => `Edit ${properties.attendee.user?.full_name} info`,
);

const form = reactive<
  Pick<DatabaseTable<"course_session_attendees">, "enrolment_mode">
>({
  enrolment_mode: properties.attendee.enrolment_mode,
});

const { close } = useModal("courseSessionAttendeeEditionModal");

const onSubmit = () => close({ confirmed: true, payload: form });
</script>
